import React from "react"

import { OrdersItem } from "../Item/OrdersItem"
import { Body } from "../../../Styled/Text"

export const OrdersList = ({
  orders,
  loading,
  error,
  additionalNoOrdersText,
  additionalOrderNamePrefixText,
  additionalOrderPurchasedOnPrefixText,
  additionalTrackOrderButtonText,
  additionalViewOrderButtonText,
}): JSX.Element => (
  <>
    {!loading && orders?.length ? (
      orders?.map(order => (
        <OrdersItem
          key={order?.id}
          {...order}
          additionalOrderNamePrefixText={additionalOrderNamePrefixText}
          additionalOrderPurchasedOnPrefixText={additionalOrderPurchasedOnPrefixText}
          additionalTrackOrderButtonText={additionalTrackOrderButtonText}
          additionalViewOrderButtonText={additionalViewOrderButtonText}
        />
      ))
    ) : (
      <Body>{additionalNoOrdersText}</Body>
    )}
    {error && <Body colour={"red"}>Error loading orders</Body>}
  </>
)

import React from "react"

export const withOrders = Component => ({ name = "Orders", page, uri }) => {
  const title = page?.title

  const paths = [
    {
      title,
    },
  ]

  Component.displayName = name
  return <Component title={title} uri={uri} paths={paths} />
}
